/* General
  * Use this file for general styles that are not specific to a component e.g. Spacing, Breakpoints.
*/

.app-container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 100vh;
  min-width: 1024px;
  overflow: hidden;
}

.map-area {
  display: flex;
  position: fixed;
  min-width: 1024px;
  width: 100%;
  height: calc(100% - 48px);
}

.mapbox-improve-map {
  display: none;
}

.mapboxgl-ctrl-attrib-inner {
  color: rgba(0, 0, 0, 0.75);
}

.mapboxgl-popup-content {
  background-color: #212b3a;
  padding: 8px;
  border-radius: 6px;
  text-align: center;
}

.mapboxgl-popup-anchor-top > .mapboxgl-popup-tip {
  border-bottom-color: #212b3a !important;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: #212b3a !important;
}

.mapboxgl-popup-anchor-left > .mapboxgl-popup-tip {
  border-right-color: #212b3a !important;
}

.mapboxgl-popup-anchor-right > .mapboxgl-popup-tip {
  border-left-color: #212b3a !important;
}
